/**
* if set to device-width ie10 built-in scrollbar overflows content. Fix it as of here
* http://stackoverflow.com/questions/17045132/scrollbar-overlay-in-ie10-how-do-you-stop-that-might-be-bootstrap
*/
@-ms-viewport {
  width: auto;
}

html, body {
  height: 100%;
}

body {
  font-weight: $font-weight-base;
  overflow-x: hidden;
}

th {
  font-weight: $font-weight-semi-bold;
}

.hidden {
  display: none !important;
}

.modal-open {
  @media print {
    * {
      visibility: hidden;
    }

    #section-to-print {
      position: absolute;
      left: 0;
      top: 0;
    }

    #section-to-print, #section-to-print * {
      visibility: visible;
    }

  }
}
@media print {
  app, shell {
    height: 100%;
    display: block;
    position: relative;
  }
  #sidebar {
    display: none;
  }
  .tabbable.tabs-left > .nav.nav-tabs,
  .navbar-dashboard {
    display: none !important;
  }
  .content-wrap {
    margin: 0 !important;
    width: 100%;
    left: 0 !important;
  }
}

.form-field-spacing {
  padding-top: 1em;
  padding-bottom: 1em;
}

.static-text-field {
  padding-top: 7px;
  padding-bottom: 7px;
}

.required-label {
  font-weight: bold;
}

.datePicker-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.align-checkbox{
  padding-top: 26px;
}

/***********************************/
/**          FORM WIZARD          **/
/***********************************/

.form-wizard{
  .nav-pills{
    > li > a{
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: $gray-lighter;
      color: $gray-light;
      @include border-radius(3px);
      cursor: default;
    }

    > li.active > a{
      background-color: $brand-primary;
      color: $white;
    }
    > li.done > a {
      background-color: lighten($brand-primary, 28%);
      color: $white;
      cursor: pointer;
    }
    @include media-breakpoint-up(md){
      &.nav-justified{
        > li {
          padding: 0 5px;
          &:first-child{
            padding-left: 0;
          }
          &:last-child{
            padding-right: 0;
          }
        }
      }
    }
  }

  .tab-pane{
    padding: $line-height-computed 20px;
    @include border-radius(5px);
  }

  .pager > li.disabled > .btn{
    cursor: not-allowed;
    pointer-events: none; // Future-proof disabling of clicks
    opacity: .65;
    @include box-shadow(none);
  }

  //fix conflict with MODAL WIZARD
  .pager.wizard{
    display: block;
  }
}
