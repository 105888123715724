@import "./scss/_variables";
@import "./scss/_mixins";

/* Importing Bootstrap SCSS file. */
@import './scss/bootstrap/scss/bootstrap';
@import './css/bootstrap5/spacing.css';

/* Importing Font Awesome SCSS file. */
@import 'node_modules/font-awesome/scss/font-awesome';

/* Importing Glyphicons SCSS file. */
@import './scss/glyphicons-halflings/_variables';
@import './scss/glyphicons-halflings/glyphicons-halflings';
@import './scss/glyphicons-halflings/_bonus';

/* Importing Pace SCSS file. */
@import 'pace/themes/pace-theme-flash';

/* Importing Animate SCSS file. */
@import 'node_modules/animate.css/animate';

/* Importing Awesome Bootstrap Checkbox SCSS file. */
@import 'awesome-bootstrap-checkbox/awesome-bootstrap-checkbox';

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

/* Importing Messenger SCSS file. */
@import "./scss/messenger/messenger";
@import "./scss/messenger/messenger-spinner";
@import "./scss/messenger/messenger-theme-air";
@import "./scss/messenger/messenger-theme-block";
@import "./scss/messenger/messenger-theme-flat";
@import "./scss/messenger/messenger-theme-future";
@import "./scss/messenger/messenger-theme-ice";

@import "./scss/_base";
@import "./scss/_bootstrap-override";
@import "./scss/_font";
@import "./scss/_general";
@import "./scss/_global-transitions";
@import "./scss/_libs-override";
@import "./scss/_loader";
@import "./scss/_utils";
@import "./scss/_variables-mixins";
@import "./scss/_widgets";
@import "./scss/_messenger";

.layout-wrapper {
  margin: 0;
  padding: 0;
}

.set-height {
  min-height: calc(100vh - 40px);
}

.public-primary-color {
  color: #FC4500 !important;
}

.public-content {
  min-height: 100%;
  padding: $content-padding-top $content-padding-horizontal $content-padding-vertical;
  background-color: $body-bg;
  color: #3E3A49;

  @include media-breakpoint-down(sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
