@import url("//fonts.googleapis.com/css?family=Raleway:400");
/* line 12, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice {
    -moz-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-family: "Raleway", sans-serif;
}

/* line 16, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.14), 0 4px #aaaaaa, 0 5px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.14), 0 4px #aaaaaa, 0 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.14), 0 4px #aaaaaa, 0 5px rgba(0, 0, 0, 0.05);
    border: 0px;
    background-color: #f6f6f6;
    position: relative;
    margin-bottom: 1.5em;
    font-size: 13px;
    color: #666666;
    font-weight: 500;
    padding: 12px 22px;
}

/* line 28, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message .messenger-close {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #888888;
    opacity: 1;
    font-weight: bold;
    display: block;
    font-size: 20px;
    line-height: 20px;
    padding: 8px 10px 7px 7px;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}

/* line 44, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message .messenger-close:hover {
    color: #444444;
}

/* line 47, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message .messenger-close:active {
    color: #222222;
}

/* line 50, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message .messenger-actions {
    float: none;
    margin-top: 10px;
}

/* line 54, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message .messenger-actions a {
    -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.05);
    -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.05);
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    position: relative;
    text-decoration: none;
    display: inline-block;
    padding: 10px;
    color: #888888;
    margin-right: 10px;
    padding: 3px 10px 5px;
    text-transform: capitalize;
}

/* line 66, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message .messenger-actions a:hover, ul.messenger-theme-ice .messenger-message .messenger-actions a:active {
    -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.15), 0 2px #aaaaaa;
    -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.15), 0 2px #aaaaaa;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.15), 0 2px #aaaaaa;
    color: #444444;
}

/* line 70, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message .messenger-actions a:active {
    -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.15), 0 1px #aaaaaa;
    -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.15), 0 1px #aaaaaa;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px 1px rgba(255, 255, 255, 0.15), 0 1px #aaaaaa;
    top: 1px;
}

/* line 74, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message .messenger-actions .messenger-phrase {
    display: none;
}

/* line 77, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message .messenger-message-inner:before {
    display: block;
    z-index: 20;
    font-weight: bold;
    margin-bottom: 2px;
}

/* line 84, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message.alert-success .messenger-message-inner:before {
    content: "Success";
}

/* line 88, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message.alert-error .messenger-message-inner:before {
    content: "Error";
}

/* line 92, ../../src/sass/messenger-theme-ice.sass */
ul.messenger-theme-ice .messenger-message.alert-info .messenger-message-inner:before {
    content: "Information";
}
